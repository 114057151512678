<template>
  <div>
    <el-button type="success" @click="addOne">+添加IP标签</el-button>
    <el-button type="primary" @click="refresh">刷新列表</el-button>
    <el-table :data="dataList" border style="width: 100%;margin-top: 2vh;">

      <el-table-column prop="id" header-align="center" align="center" label="ID">
      </el-table-column>

      <el-table-column prop="description" header-align="center" align="center" label="IP描述">
        <template slot-scope="scope">
          <span v-show="!scope.row.isEditMode">{{ scope.row.description }}</span>
          <el-input v-model="scope.row.description" v-show="scope.row.isEditMode" />
        </template>
      </el-table-column>

      <el-table-column prop="langIp.en" header-align="center" align="center" label="英语IP">
        <template slot-scope="scope">
          <span v-show="!scope.row.isEditMode">{{ scope.row.langIp.en }}</span>
          <el-input v-model="scope.row.langIp.en" v-show="scope.row.isEditMode" />
        </template>
      </el-table-column>

      <el-table-column prop="langIp.ar" header-align="center" align="center" label="阿语IP">
        <template slot-scope="scope">
          <span v-show="!scope.row.isEditMode">{{ scope.row.langIp.ar }}</span>
          <el-input v-model="scope.row.langIp.ar" v-show="scope.row.isEditMode" />
        </template>
      </el-table-column>


      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-button size="mini" type="warning" v-show="!scope.row.isEditMode" @click="edit(scope.row, scope)">编辑
          </el-button>

          <el-button size="mini" type="success" v-show="scope.row.isEditMode" @click="saveOrEdit(scope.row)">保存
          </el-button>
          <el-button size="mini" type="danger" v-show="scope.row.isEditMode" @click="cancel(scope)">取消</el-button>

          <el-button size="mini" type="danger" v-show="!scope.row.isEditMode" @click="del(scope.row)">删除</el-button>
        </template>
      </el-table-column>

    </el-table>
  </div>
</template>

<script>

export default {
  data() {
    return {
      dataList: []
    }
  },
  created() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$http({
        url: this.$http.adornUrl(`/admin/virtualIp/all`),
        method: 'get'
      }).then(({ data }) => {
        if (data && data.code === 0) {
          this.dataList = data.result.map(item => {
            return {
              ...item,
              isEditMode: false
            };
          });
        } else {
          this.$message.error(data.msg)
        }
      })
    },
    cancel(scope) {
      if (!scope.row.id) {
        this.dataList.splice(scope.$index, 1)
      } else {
        scope.row.isEditMode = false
      }
    },
    edit(row) {
      row.isEditMode = true
    },
    del(row) {
      this.$confirm('IP信息对于客户端影响大，确认删除？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/virtualIp/delete?id=${row.id}`),
          method: 'delete'
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })
    },
    saveOrEdit(row) {
      this.$confirm('IP信息对于客户端影响大，当前配置是否已经确认无误？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$http({
          url: this.$http.adornUrl(`/admin/virtualIp/${row.id ? 'update' : 'add'}`),
          method: 'post',
          data: {
            ...row
          }
        }).then(({ data }) => {
          if (data && data.code === 0) {
            this.getDataList()
            this.$message({
              message: '操作成功',
              type: 'success',
              duration: 1000,
            })
          } else {
            this.$message.error(data.msg)
          }
        })
      })


    },
    addOne() {
      this.dataList.unshift({
        isEditMode: true,
        description: '',
        langIp: {
          ar: '',
          en: ''
        }
      })
    },
    refresh() {
      this.getDataList()
    }
  }
}
</script>

<style></style>